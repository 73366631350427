import {useState, useEffect} from 'react'
import {TextField, Button} from '@mui/material';
import './App.css';

function App() {
  return (
    <>
      <Nav></Nav>
      <Home></Home>
      <Testimonials></Testimonials>
      <AboutMe></AboutMe>
      <Services></Services>
      <Pictures></Pictures>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
}

const Pictures = () => {
  var fontSize = FontEqualizer()
  const pictures = importAllPics(require.context('../public/beforeAndAfter', false, /\.(JPG)$/));

  function importAllPics(r) {
    const items = r.keys().map(r);
    return items.map((item) => {
      const firstIndex = item.lastIndexOf("/") + 1
      const secondIndex = item.indexOf(".")
      return item.substring(firstIndex, secondIndex)
    })
  }

  const [clicked, setClicked] = useState("")

  console.log(pictures)
  return (
    <div className='picturesContainer'>
      <p style={{fontSize: `${fontSize*1.5}px`}} >Before & After Examples</p>
      <div className='pictures'>
        {pictures.map((picture) => {
          return <div className={'picture '+((picture == clicked)? "bigPicture" : "")} onClick={()=>{(picture == clicked) ? setClicked("") : setClicked(picture)}} style={{backgroundImage: 'url(/beforeAndAfter/'+picture+'.JPG)'}}></div>
        })}
      </div>
    </div>
  )
}

const FontEqualizer = () => {
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const updateFontSize = () => {
      const widthProportion = window.innerWidth * 0.0085
      const height = window.innerHeight  * 0.0085
      if (height > widthProportion){
        setScreenWidth(widthProportion*2);
      }else{
        setScreenWidth(widthProportion);
      }
    };

    updateFontSize();
    window.addEventListener('resize', updateFontSize);

    return () => window.removeEventListener('resize', updateFontSize);
  }, []);

  return screenWidth
}


const AboutMe = () => {
  var fontSize = FontEqualizer()

  return (
    <div id="aboutme" className='aboutmeContainer'>
      <div className='aboutme'>
          <h1 style={{ fontSize: `${fontSize*4.5}px` }}>Meet Your Organizer</h1>
        </div>
      <div className='aboutmeContent'>
        <p style={{ fontSize: `${fontSize*1.25}px` }}>Hello! My name is Amanda Kniatt, owner of OCD, Organizing and I am here to organize, create and declutter ANY space in your home or business. From a very young age I have always loved rearranging & organizing my room & continued to do the same thing as an adult in my own home. <br></br><br></br>I truly believe a clean and organized environment gives you a renewed and calm mindset and overall well-being. As a professional organizer, I come to your space judgement free with the goal to redesign & reclaim your space so it is functional & beautiful!</p>
      </div>
      <img src={require("./assets/amanda.png")}></img>
    </div>
  )
}

const Services = () => {
  var fontSize = FontEqualizer()
  return (
    <div id="services" className='servicesContainer'>
      <h1 style={{ fontSize: `${fontSize*4}px` }}>Services We Provide</h1>
    <div className='services'>
      <div className='item'>
        <img src={require("./assets/bedroom.png")}></img>
        <h1 style={{ fontSize: `${fontSize*1.75}px` }}>Organizing By Room</h1>
        <p style={{ fontSize: `${fontSize*1}px` }} className='quote'>
        Closet, Kitchen, Pantry, Bedroom, Office, Garage, etc.
        </p>
      </div>
      <div className='item'>
        <img src={require("./assets/trading-card.png")}></img>
        <h1 style={{ fontSize: `${fontSize*1.75}px` }}>Organizing By Project</h1>
        <p style={{ fontSize: `${fontSize*1}px` }} className='quote'>
        Photos, Books, Collectables, Seasonal Decor, Crafts, etc.
        </p>
      </div>
      <div className='item'>
        <img src={require("./assets/box.png")}></img>
        <h1 style={{ fontSize: `${fontSize*1.75}px` }}>Relocating Services</h1>
        <p style={{ fontSize: `${fontSize*1}px` }} className='quote'>
        Packing, Unpacking, Pre-move Purging
        </p>
      </div>
      <div className='item'>
        <img src={require("./assets/christmas.png")}></img>
        <h1 style={{ fontSize: `${fontSize*1.75}px` }}>Staging & Decorating</h1>
        <p style={{ fontSize: `${fontSize*1}px` }} className='quote'>
       Gift Wrapping, Party or Special Occasion Preparation, etc.
        </p>
      </div>
    </div>
    </div>
  )
}

const Contact = () => {
  var fontSize = FontEqualizer()


  return (
    <div id="contactme" className='contactmeContainer'>
      <div className='contactme'>
        <h1 style={{fontSize: `${fontSize*2.5}px`}}>Schedule your free assessment!</h1>
        <form name="contact" method="POST" >
          <input type="hidden" name="form-name" value="contact"/>
          <TextField  inputProps={{style: {height: `${fontSize*1}px`, fontSize: `${fontSize*1}px`}}} InputLabelProps={{style: {fontSize: `${fontSize*1}px`}}}
          className='formItem short' required label="Name" name="name" margin="dense"/>
          <TextField  inputProps={{style: {height: `${fontSize*1}px`, fontSize: `${fontSize*1}px`}}} InputLabelProps={{style: {fontSize: `${fontSize*1}px`}}}
          className='formItem short' required label="Email" name="email" margin="dense"/>
          <TextField  inputProps={{style: {height: `${fontSize*1}px`, fontSize: `${fontSize*1}px`}}} InputLabelProps={{style: {fontSize: `${fontSize*1}px`}}}
          className='formItem short' required label="Phone" name="phone" margin="dense"/>
          <TextField  inputProps={{style: {height: `${fontSize*1}px`, fontSize: `${fontSize*1}px`}}} InputLabelProps={{style: {fontSize: `${fontSize*1}px`}}}
          className='formItem short' label="Address (optional)" name="address"  margin="dense"/>
          <TextField  inputProps={{style: {height: `${fontSize*1}px`, fontSize: `${fontSize*1}px`}}} InputLabelProps={{style: {fontSize: `${fontSize*1}px`}}}
          className='formItem' required label="Desired Services" name="services"  margin="dense"/>
          <TextField   inputProps={{style: {height: `${fontSize*1}px`, fontSize: `${fontSize*1}px`}}} InputLabelProps={{style: {fontSize: `${fontSize*1}px`}}}
          className='formItem' required label="How did you hear about us?" name="reference"  margin="dense"/>
          <Button className='formItem button' type="submit" variant="contained" color="primary"
          sx={{ fontSize: `${fontSize*1}px`,  backgroundColor: '#023047', marginTop: '3%', marginBottom: '1%'   }} >
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

const Testimonials = () => {
  var fontSize = FontEqualizer()
  return (
    <div id="testimonials" className='testimonialsContainer'>
      <h1 style={{ fontSize: `${fontSize*3}px` }} >What Our Clients Have To Say</h1>
    <div className='testimonials'>
      <div className='items'>
      <div className='item'>
          <p style={{ fontSize: `${fontSize*1.15}px` }} className='quote'>
          "I can truly say organizing is her niche. She has helped me with many organizing projects and she truly enjoys what she does, strives and succeeds at being the best. She is genuine about her passion of helping others having a clutter free, more organized, therefore less stressful environment!"
          </p>
          <p style={{ fontSize: `${fontSize*2}px` }} className='signature'>
          - Rebecca T.
          </p>
        </div>
        <div className='item'>
          <p  style={{ fontSize: `${fontSize*1.15}px` }}className='quote'>
          "I am so happy with Amanda. I guarantee this will be a lasting relationship with repeat seasons. In just 3 hours she made my kitchen organized and functional. I am physically disabled and she was empathetic as well as listened to what I needed in cupboard placement. I highly recommend her."
          </p>
          <p  style={{ fontSize: `${fontSize*2}px` }} className='signature'>
          - Wendy S.
          </p>
        </div>
      </div>
      <div className='items'>
      <div className='item'>
          <p style={{ fontSize: `${fontSize*1.15}px` }} className='quote'>
          "Amanda was a godsend. I’m downsizing—she took an overwhelming (for me) task and got it done. She was very motivating, energetic and positive. I would hire her again in a heartbeat."
          </p>
          <p style={{ fontSize: `${fontSize*2}px` }} className='signature'>
          - Patricia K.
          </p>
        </div>
        <div className='item'>
          <p style={{ fontSize: `${fontSize*1.15}px` }} className='quote'>
          "Amanda was responsive and showed up on time and got to work. She had some great ideas and worked very efficiently to get my basement arranged and organized based on my expectations."
          </p>
          <p style={{ fontSize: `${fontSize*2}px` }} className='signature'>
          - Elizabeth U.
          </p>
        </div>

        </div>
    </div>
    </div>
  )
}


const Nav = () => {
  var fontSize = FontEqualizer()
  const [navSize, setNavSize] = useState("thin")
  useEffect(()=> {
      const handleScroll = () => {
         let p = window.scrollY
          setNavSize((p > 40) ? "wide" : (navSize === "wide" || navSize === "thin") ? "thin" : "")
      };
      window.addEventListener("scroll", handleScroll);
      return(() => {
         window.removeEventListener("scroll", handleScroll);
      })
  })

  return (
    <div className={"navContainer " + navSize}>
      <div  style={{ fontSize: `${fontSize*1.25}px` }} className="nav">
        <div>
        <a href="#testimonials">Reviews</a>
        <a href="#aboutme">About Me</a>
        </div>
          <a href="#home">
            <img alt="OCD Organizing logo" src={require("./assets/logo.png")}></img>
          </a>
        <div>
        <a href="#services">Services</a>
        <a href="#contactme">Contact Me</a>
        </div>
      </div>
    </div>
  )
}

const Home = () => {
  var fontSize = FontEqualizer()
  return (
    <div className='home' id="home">
      <h1 style={{ fontSize: `${fontSize*6}px` }}>Organize, create, &<br></br>declutter your space.</h1>
      <div  style={{ fontSize: `${fontSize*1.5}px` }}className="bookNow"><a href="#contactme">SCHEDULE NOW</a></div>
    </div>
  )
}

const Footer = () => {
  var fontSize = FontEqualizer()
  return (
    <div>
    <div className='footer'>
        <div className='footercol'>
          <h1 style={{ fontSize: `${fontSize*2.25}px` }}>Navigation</h1>
          <p style={{ fontSize: `${fontSize*1}px` }}><a href="#home">Home</a></p>
          <p style={{ fontSize: `${fontSize*1}px` }}><a href="#testimonials">Reviews</a></p>
          <p style={{ fontSize: `${fontSize*1}px` }}><a href="#aboutme">About Me</a></p>
          <p style={{ fontSize: `${fontSize*1}px` }}><a href="#services">Services</a></p>
          <p style={{ fontSize: `${fontSize*1}px` }}><a href="#contactme">Contact Me</a></p>
        </div>
        <div className='footercol location'>
          <h1 style={{ fontSize: `${fontSize*2.25}px` }}>Location</h1>
          <p style={{ fontSize: `${fontSize*1}px` }}>Serving Culpeper, Virginia <br></br>and surrounding counties </p>
          <p style={{ fontSize: `${fontSize*1}px` }}><a target="_blank" href="mailto:ocdorganize4u@gmail.com">ocdorganize4u@gmail.com</a></p>
          <p style={{ fontSize: `${fontSize*1}px` }}><a target="_blank" href="tel:540-905-5993">540-905-5993</a></p>
        </div>
        <div className='footercol social'>
          <h1 style={{ fontSize: `${fontSize*2.25}px` }}>Social Media</h1>
          <div>
            <a target="_blank" href="https://facebook.com/ocd.homeorganizer"><img alt="facebook" src={require("./assets/facebook.png")}></img></a>
            <a target="_blank" href="https://www.instagram.com/ocd.homeorganizer/"><img alt="instagram" src={require("./assets/instagram.png")}></img></a>
            <a target="_blank" href="https://tiktok.com/ocd.homeorganizer"><img alt="tiktok" src={require("./assets/tiktok.png")}></img></a>
          </div>
        </div>
    </div>
    <div style={{ fontSize: `${fontSize*1}px` }} className='createdby'>
        Website by <a href="http://onebytewonders.com" target='_blank'>OneByteWonders</a>
    </div>
    </div>
  )
}

export default App;
